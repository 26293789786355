<template>
  <div class="form-elements">
    <div class="var-row">
      <div class="flex md12">
        <vuestic-widget>
          <form autocomplete="off">
            <div class="va-row">
              <div class="flex md12">
                <fieldset>
                  <div
                    class="va-row"
                    id="alinha"
                  >
                    <!-- SELECIONAR Ferramenta -->
                    <div class="flex md4 xs12">
                      <div class="form-group">
                        <label class="select-control-label">Ferramenta</label>
                        <multiselect
                          :disabled="desabilitar.ferramenta"
                          v-model="ferramentaSelecionadas"
                          placeholder="Ferramentas"
                          track-by="id"
                          label="ferramenta"
                          :options="ferramentas"
                          selectedLabel="Selecionado"
                          select-label="Selecione uma ferramenta"
                          deselect-label="Remover ferramenta"
                        >
                          <span slot="noResult">Ferramenta não encontrado.</span>
                        </multiselect>
                      </div>
                      <span
                        class="alert-danger error"
                        v-if="erros.ferramenta"
                      >{{erros.ferramenta}}</span>
                    </div>
                    <!-- SELECIONAR ÁREA -->
                    <div class="flex md4 xs12">
                      <div class="form-group">
                        <label class="select-control-label">Área</label>
                        <multiselect
                          v-model="areaSelecionada"
                          placeholder="Selecione"
                          track-by="id"
                          label="nome"
                          :options="areas"
                          selectedLabel="Selecionado"
                          select-label="Selecione uma área"
                          deselect-label="Remover área"
                        >
                          <span slot="noResult">Área não encontrado.</span>
                        </multiselect>
                      </div>
                      <span
                        class=" alert-danger"
                        v-if="erros.area"
                      >{{erros['area']}}</span>
                    </div>

                    <!-- Assunto -->
                    <div class="flex md4 xs12">
                      <div class="form-group">
                        <input
                          :maxlength="100"
                          v-model="acao.assunto"
                          id="assunto"
                          required
                        />
                        <label
                          class="control-label"
                          for="assunto"
                        >Assunto ({{tamanhoAssunto(100)}} / 100) *
                        </label>
                      </div>
                      <span
                        class="alert-danger"
                        v-if="erros['assunto']"
                      >{{erros['assunto']}}</span>
                    </div>
                  </div>

                  <div class="va-row">
                    <div class="flex md4 xs12">
                      <div class="form-group">
                        <div class="input-group">
                          <textarea
                            :maxlength="300"
                            type="text"
                            id="simple-textarea-oque"
                            v-model="acao.o_que"
                            required
                          ></textarea>
                          <label
                            class="control-label"
                            for="simple-textarea-oque"
                          >O que fazer? ({{tamanhoOque(300)}} / 300) *</label>
                        </div>
                      </div>
                      <span
                        class="alert-danger"
                        v-if="erros['oQue']"
                      >{{erros['oQue']}}
                      </span>
                    </div>

                    <div class="flex md4 xs12">
                      <div class="form-group">
                        <div class="input-group">
                          <textarea
                            :maxlength="300"
                            type="text"
                            id="simple-textarea-como"
                            v-model="acao.como"
                            required
                          ></textarea>
                          <label
                            class="control-label"
                            for="simple-textarea-como"
                          >Como fazer? ({{tamanhoComo(300)}} / 300) *</label>
                        </div>
                      </div>
                      <span
                        class="alert-danger"
                        v-if="erros && erros['como']"
                      >{{erros['como']}}</span>
                    </div>

                    <div class="flex md4 xs12">
                      <div class="form-group">
                        <label class="select-control-label">Quem?</label>
                        <multiselect
                          v-model="responsavelSelecionado"
                          placeholder="Responsável"
                          label="nome"
                          selectLabel="Selecione um responsável"
                          selectedLabel="Selecionado"
                          track-by="id"
                          deselectLabel="Remover responsável"
                          :close-on-select="false"
                          :options="responsaveis"
                          :multiple="true"
                        >
                          <span slot="noResult">Responsável não encontrado.</span>
                        </multiselect>

                      </div>
                      <span
                        class="alert-danger"
                        v-if="erros['responsavel']"
                      >{{erros['responsavel']}}</span>
                    </div>
                  </div>

                  <div class="va-row">
                    <div class="flex md4 xs12">
                      <div class="form-group">
                        <cascader
                          ref="cascader"
                          class="full-width"
                          v-model="acao.indicador"
                          placeholder="Indicadores"
                          :options="indicadores"
                          :value="acao.indicador"
                        ></cascader>
                      </div>
                    </div>

                    <div class="flex md4 xs12">
                      <div class="form-group">
                        <div class="data-group">
                          <vuestic-date-picker
                            :config="{
                              altInput: true,
                              altFormat: 'd/m/Y',
                              dateFormat: 'Y-m-d', }"
                            id="criacao"
                            v-model="acao.data_cadastro"
                          />
                          <label
                            class="control-label"
                            for="criacao"
                          >
                            Data de Cadastro
                          </label>

                        </div>
                      </div>
                      <span
                        class=" alert-danger"
                        v-if="erros['cadastro']"
                      >{{erros['cadastro']}}</span>
                    </div>
                    <div class="flex md4 xs12">
                      <div class="form-group">
                        <div class="data-group">
                          <vuestic-date-picker
                            :config="{
                              altInput: true,
                              altFormat: 'd/m/Y',
                              dateFormat: 'Y-m-d', }"
                            id="final"
                            v-model="acao.prazo"
                          />
                          <label
                            class="control-label"
                            for="final"
                          >
                            Prazo
                          </label>
                        </div>
                      </div>

                      <span
                        class=" alert-danger"
                        v-if="erros['prazo']"
                      >{{erros['prazo']}}</span>
                    </div>
                  </div>

                  <div class="va-row">
                    <div class="flex md12 xs12">
                      <div class="form-group">
                        <div class="input-group">
                          <textarea
                            :maxlength="500"
                            type="text"
                            id="simple-textarea"
                            v-model="acao.comentario"
                            required
                          ></textarea>
                          <label
                            class="control-label"
                            for="simple-textarea"
                          >Descrição</label>
                        </div>
                        <el-upload
                          class="upload-demo"
                          action=""
                          :auto-upload="false"
                          :limit="1"
                          type="file"
                          name="Imagem"
                          accept="image/*"
                          ref="upload"
                          @change="() => null"
                          :on-change="selectImg"
                          :on-remove="removeImg"
                        >
                          <i class="foto-icon el-icon-camera"></i>
                        </el-upload>
                      </div>
                    </div>
                  </div>

                  <div class="butoes mt-3">
                    <button
                      class="btn btn-pale btn-micro"
                      @click="$emit('exit')"
                    >
                      Voltar
                    </button>
                    <button
                      class="btn btn-primary btn-micro"
                      type="button"
                      @click.prevent="validateForm()"
                    >Salvar</button>
                  </div>
                </fieldset>
              </div>
            </div>
          </form>
        </vuestic-widget>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import Multiselect from 'vue-multiselect';
import { mapActions } from 'vuex';

import cascader from '../widget/cascader.vue';

export default {
  components: {
    Multiselect,
    cascader,
  },
  props: {
    acaoDefaut: {
      type: Object,
    },
  },
  name: 'cadastrar-acao',
  data() {
    return {
      show: true,
      empresa: [],
      indicadores: [],
      desabilitado: false,
      erros: {
        area: '',
        ferramenta: '',
        assunto: '',
        oQue: '',
        como: '',
        prazo: '',
        responsavel: '',
        cadastro: '',
      },
      areas: [],
      areaSelecionada: {},
      ferramentaSelecionadas: {},
      responsaveis: [],
      ferramentas: [],
      responsavelSelecionado: [],
      acao: {
        assunto: '',
        comentario: '',
        o_que: '',
        como: '',
        local: '',
        data_cadastro: new Date(),
        prazo: null,
        data_finalização: null,
        situacao: 1,
        comentario_finalização: '',
        origem: null,
        responsavel: null,
        area: null,
        empresa: null,
        responsavel_cadastro: null,
        indicador: [],
      },
    };
  },
  created() {
    this.acao.empresa = JSON.parse(localStorage.empresaSelecionada);
    this.acao.responsavel_cadastro = JSON.parse(localStorage.user).funcionario.id;
    this.idAcao = this.$router.history.current.params.id;
    this.acao.data_cadastro = moment().format('YYYY-MM-DD');
    this.getEmpresa();
    this.getResponsaveis();
  },
  methods: {
    ...mapActions(['selectImage', 'transformObjectInFormData']),
    removeImg() {
      this.acao.imagem = null;
      this.$refs.upload.clearFiles();
    },
    async selectImg(event) {
      this.acao.imagem = await this.selectImage([event.raw]);
    },
    validateForm() {
      const { acao } = this;
      let count = 0;
      this.erros = [];
      if (!acao.assunto) {
        this.erros.assunto = 'O campo "assunto" é obrigatório.';
        count += 1;
      }
      if (!this.ferramentaSelecionadas.id) {
        this.erros.ferramenta = 'O campo "ferramenta" é obrigatório.';
        count += 1;
      }
      if (!acao.o_que) {
        this.erros.oQue = 'O campo "o que fazer" é obrigatório.';
        count += 1;
      }
      if (!acao.como) {
        this.erros.como = 'O campo "como fazer" é obrigatório.';
        count += 1;
      }
      if (this.responsavelSelecionado.length < 1) {
        this.erros.responsavel = 'O campo "quem" é obrigatório.';
        count += 1;
      }
      if (!acao.data_cadastro) {
        this.erros.cadastro = 'O campo "data de cadastro" é obrigatório.';
        count += 1;
      }
      if (!this.areaSelecionada.id) {
        this.erros.area = 'O campo "área" é obrigatório.';
        count += 1;
      }
      if (!acao.prazo) {
        this.erros.prazo = 'O campo "prazo" é obrigatório.';
        count += 1;
      }
      if (count === 0) {
        if (this.responsavelSelecionado.length <= 1) {
          this.cadastrarAcao();
        } else {
          this.enviarMultiplasAcoes();
        }
      }
    },
    limparErros() {
      this.erros = {
        area: '',
        ferramenta: '',
        assunto: '',
        oQue: '',
        como: '',
        prazo: '',
        responsavel: '',
        cadastro: '',
      };
    },
    cleanCascader() {
      this.$refs.cascader.clean();
    },
    adicionarAcaoPorProps() {
      const { acaoDefaut } = this;
      Object.keys(acaoDefaut).forEach((key) => {
        if (key === 'area') {
          this.areaSelecionada = acaoDefaut[key];
        } else if (key === 'responsavel') {
          this.responsavelSelecionado = acaoDefaut[key];
        } else if (key === 'ferramenta') {
          this.ferramentaSelecionadas = acaoDefaut[key];
        } else {
          this.acao[key] = acaoDefaut[key];
        }
      });
    },
    getEmpresa() {
      this.$axios.get(`/api/empresa-list/${this.acao.empresa}/`).then((res) => {
        this.empresa = res.data;
        this.getAreas();
        this.getFerramentas();
        this.getIndicadores();
      });
    },
    getAreas() {
      this.$axios.get('/api/area-list/').then((res) => {
        // eslint-disable-next-line
        this.areas = (res.data).filter(area => this.empresa.areas.indexOf(area.id) != -1);
      });
    },
    getIndicadores() {
      this.$axios.get('/api/indicador-list/').then((res) => {
        // eslint-disable-next-line
        this.indicadores = (res.data).filter(indicador => this.empresa.indicador.indexOf(indicador.id) != -1);
      });
    },
    getResponsaveis() {
      this.$axios.get('/api/funcionario-list/').then((res) => {
        // eslint-disable-next-line
        const filtraAcaoEmpresa = (res.data).filter(funcionario => funcionario.empresas.indexOf(this.acao.empresa) != -1);
        // eslint-disable-next-line
        this.responsaveis = filtraAcaoEmpresa.sort((obj1, obj2) => ((obj1.nome).toUpperCase() < (obj2.nome).toUpperCase() ? -1 : (obj1.nome).toUpperCase() > (obj2.nome).toUpperCase() ? 1 : 0));
      });
    },
    statusDaSituacao() {
      const hoje = moment().format('YYYY-MM-DD');
      const { prazo } = this.acao;
      return hoje <= prazo ? 1 : 2;
    },
    async enviarMultiplasAcoes() {
      const acoes = await this.arrayAcoes();
      this.$axios.post('/api2/acao/multiplos/', acoes).then(() => {
        this.message('success', 'Ação replicada com sucesso');
        this.limparCampos();
      }).catch((error) => {
        this.erros = error.response.data;
        this.message('error', 'Erro!! Não foi possivel replicar a ação');
      });
    },
    async arrayAcoes() {
      this.filtrarAreaResponsavelEFerramenta();
      const novaAcao = this.acao;
      const situacao = await this.statusDaSituacao();
      const arrayIds = await this.responsavelSelecionado.map(r => r.id);
      const acoes = [];
      arrayIds.forEach(async (id) => {
        const { ...acao } = novaAcao;
        acao.responsavel = await id;
        acao.situacao = situacao;
        acoes.push({ ...acao });
      });
      return acoes;
    },
    tamanhoAssunto(tamanho) {
      return tamanho - this.acao.assunto.length;
    },
    tamanhoOque(tamanho) {
      return tamanho - this.acao.o_que.length;
    },
    tamanhoComo(tamanho) {
      return tamanho - this.acao.como.length;
    },
    getFerramentas() {
      this.$axios.get('/api/ferramenta-list/').then((res) => {
        this.ferramentas = (res.data).filter(f => this.empresa.ferramenta.indexOf(f.id) !== -1);
      });
    },
    filtrarAreaResponsavelEFerramenta() {
      if (this.areaSelecionada) {
        this.acao.area = this.areaSelecionada.id;
      }
      if (this.responsavelSelecionado) {
        this.acao.responsavel = this.responsavelSelecionado[0].id;
      }
      if (this.ferramentaSelecionadas) {
        this.acao.origem = this.ferramentaSelecionadas.id;
      }
    },
    async cadastrarAcao() {
      this.filtrarAreaResponsavelEFerramenta();
      const acaoAux = await this.transformObjectInFormData(this.acao);
      this.$axios.post('/api2/acao.json', acaoAux).then(() => {
        this.limparCampos();
        this.$emit('success');
        this.message('success', 'Cadastrado com Sucesso!');
      }).catch((err) => {
        this.message('error', 'Erro!');
        this.$emit('error');
        this.erros = err.response.data;
      });
    },
    message(type, title) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showCancelButton: false,
        type,
        title,
        showConfirmButton: false,
        timer: 2000,
      });
    },
    limparCampos() {
      this.removeImg();
      this.limparErros();
      this.cleanCascader();
      this.areaSelecionada = {};
      this.ferramentaSelecionadas = {};
      this.responsavelSelecionado = [];
      this.acao = {
        assunto: '',
        comentario: '',
        o_que: '',
        como: '',
        local: '',
        data_cadastro: moment().format('YYYY-MM-DD'),
        prazo: null,
        data_finalização: null,
        situacao: 1,
        comentario_finalização: '',
        origem: null,
        responsavel: null,
        area: null,
        indicador: [],
      };
      this.acao.empresa = JSON.parse(localStorage.empresaSelecionada);
      this.acao.responsavel_cadastro = JSON.parse(localStorage.user).funcionario.id;
    },
  },
  computed: {
    desabilitar() {
      const { acaoDefaut } = this;
      return {
        ferramenta: !!(acaoDefaut && acaoDefaut.ferramenta),
      };
    },
  },
};

</script>

<style>
.el-upload__input {
	display: none !important;
}
.foto-icon {
	position: absolute;
	top: 26px;
	right: 9px;
	font-size: 2rem;
}

.el-upload-list__item-name {
	color: #606266 !important;
}
.el-upload-list {
	position: absolute;
	top: 52px;
	right: -4px;
}
</style>

<style lang="scss" scoped>
$break-small: 520px;
$break-large: 1200px;
.butoes {
	display: flex;
	justify-content: flex-end;
	@media screen and (max-width: $break-small) {
	}
}
@media screen and (max-width: $break-small) {
	.butoes {
		justify-content: space-between;
	}
	.butoes button {
		flex-basis: 50%;
	}
}
.butoes .btn-pale {
	margin-right: 0.8%;
}

.label {
	margin: 5px;
}
body {
	line-height: 0.9rem;
}
.form-group .flatpickr-input {
	background: none;
}
.form-group {
	margin-bottom: 0.4rem !important;
}
.margin {
	margin-left: 8px;
}
.alinha .input-group {
	margin-top: 38px;
	margin-bottom: -38px;
}

@media screen and (max-width: 550px) {
	.alinha .input-group {
		margin-top: 0px !important;
		margin-bottom: 0px !important;
	}
	#alinha .multiselect {
		margin-bottom: 0% !important;
	}
}
#alinha {
	align-items: center;
}
#alinha .multiselect {
	margin-bottom: 0.8%;
}
#alinhaIndicador .multiselect {
	margin-top: 2% !important;
}
</style>
